import Model from '@/dc-it/models/Model'
import Plan from '@/dc-it/models/Plan'
import Field from '@/dc-it/types/Field'
import Relation from '@/dc-it/types/Relation'
import MultipleRelation from '@/dc-it/types/MutipleRelation'
// import Address from '@/dc-it/models/Address'
import User from '@/dc-it/models/User'

export default class Company extends Model {
    endpoint = 'companies';

    related = ['plan', 'users']

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Field('business_name', 'Razón social'),
      new Relation('plan', 'Plan', Plan, 'name'),
      // new MultipleRelation('addresses', 'Direcciones', Address).hide(),
      new MultipleRelation('users', 'Usuarios', User).setLimit(1).hide(),
    ];

    clone = () => Company;
}
